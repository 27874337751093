.home-main {
    min-height: 100vh;
    /* Ensure the main content area takes up at least the full viewport height */
    width: 100%;
    /* Ensure the main content area is as wide as the viewport */
}

.add-button-div {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* Align button to the end of the container */
}

.table-container {
    overflow-x: auto;
    /* Allow horizontal scrolling */
    width: 100%;
    /* Ensure the container takes up the full width of the viewport */
}


.navbar-brand-home {
    /* width: 150px; */
}

.navbar-brand-home img {
    width: 150px;
    height: auto;
    object-fit: contain;
}

.job-card {
    background: #ECECEC;
    height: auto;
    width: 95%;
    padding: 15px;
    box-shadow: 3px 3px 3px 3px #adabab;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    p {
        width: 240px;
    }
}

.job-card:hover {
    width: 98%;
    box-shadow: 3px 3px 3px 5px #adabab;

}

.mob-size {
    display: none;
}

@media (min-width:300px) and (max-width: 1040px) {
    .full-size {
        display: none;
    }

    .mob-size {
        display: block;
    }
}